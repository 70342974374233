<template>
  <div :key="updateCount">
    <div :key="index" v-for="(res, index) in value.sources">
      <vca-row
        class="row-padding"
        v-if="res.money != undefined && res.edit == false"
      >
        <div class="margin-auto">
          {{ $t("taking.sources." + res.value + ".title") }}
        </div>
        <div class="margin-auto">
          {{ res.description }}
        </div>
        <div class="bold margin-auto text-center">
          {{ formatMoney(res.money) }}
        </div>
        <div class="external-box margin-auto">
          <vca-row v-if="res.has_external">
            {{ $t("taking.form.source.has_external.label") }}
          </vca-row>
        </div>
        <IconButton
          type="edit"
          v-if="res.update_state !== 'deleted'"
          :title="$t('button.edit')"
          @click="setEdit(res)"
        />
        <IconButton
          type="cancel"
          v-if="res.update_state !== 'deleted'"
          :title="$t('button.remove')"
          @click="remove(res)"
        />

        <IconButton
          v-if="res.update_state === 'deleted'"
          :title="$t('button.restore')"
          type="restore"
          @click="restore(res)"
        />
      </vca-row>

      <vca-row v-else>
        <div class="margin-auto">
          {{ $t("taking.sources." + res.value + ".title") }}
        </div>
        <div>
          <vca-input
            class="taking-margin"
            v-model="res.description"
            :placeholder="$t('taking.form.source.category.placeholder')"
          >
          </vca-input>
        </div>
        <div class="margin-auto">
          <vca-money-input
            v-model="res.money"
            ref="amount"
            :rules="$v.value.sources.$each[index].money"
            :currency="currencies"
            :select="true"
            css="small-money-input"
            :errorMsg="$t('taking.form.source.amount.errorMsg')"
            :toptext="$t('taking.form.source.amount.topText')"
          />
        </div>
        <IconButton
          type="confirm"
          @click="confirm(res, index)"
          :title="$t('button.save')"
        />
        <IconButton
          type="cancel"
          @click="remove(res)"
          :title="$t('button.cancel')"
        />
      </vca-row>
    </div>
    <vca-column>
      <h3>
        {{ $t("taking.form.source.amount.sum_total") }}
        {{ sum_total }} €
      </h3>
    </vca-column>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import IconButton from "@/components/utils/IconButton";
export default {
  name: "TakingSources",
  components: { IconButton },
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      currencies: [
        {
          label: "€",
          value: "EUR",
        },
        {
          label: "$",
          value: "USD",
        },
        {
          label: "Fr.",
          value: "CHF",
        },
      ],
      updateList: [],
      updateCount: 0,
    };
  },
  validations() {
    return this.validation;
  },
  methods: {
    remove(value) {
      value.update_state = "deleted";
      this.updateCount += 1;
    },
    validate() {
      if (this.$refs.amount) {
        this.$refs.amount.forEach((el) => {
          el.validate();
        });
      }
    },
    setEdit(value) {
      value.edit = true;
      this.updateCount += 1;
    },
    close(value) {
      value.edit = false;
      this.updateCount += 1;
    },
    confirm(value, index) {
      if (value.money && value.money.amount == 0) {
        this.$refs.amount[index].validate();
      } else {
        if (value.id !== "") {
          value.update_state = "updated";
        }
        value.edit = false;
        this.updateCount += 1;
      }
    },
    restore(value) {
      value.update_state = "";
      this.updateCount += 1;
    },
  },
  computed: {
    ...mapGetters({
      validation: "takings/source_validations",
    }),
    sum_total() {
      return (
        this.value
          ? this.value.sources.reduce(
              (sum, a) => sum + (a.money ? parseInt(a.money.amount) : 0),
              0
            ) / 100
          : 0
      ).toLocaleString();
    },
  },
};
</script>
<style lang="scss">
.taking-margin {
  margin: 5px 0;
}

.checkbox {
  max-width: fit-content;
}

.external-box {
  padding-right: 50px;

  .vca-row > .vca-checkbox {
    margin: auto;
  }
}

.row-padding {
  padding: 5.2px 0;
}

.small-money-input {
  font-size: 1em !important;
  max-width: 50px !important;
}
</style>
