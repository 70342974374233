<template>
  <vca-column>
    <vca-card class="shadowed">
      <vca-field :label="$t('taking.form.name.header')">
        <p v-html="$t('taking.form.description')"></p>
        <h3>{{ $t("taking.form.name.label") }}</h3>
        <vca-input
          v-model="value.name"
          :rules="$v.value.name"
          label=""
          ref="name"
          :errorMsg="$t('taking.form.name.errorMsg')"
          :placeholder="$t('taking.form.name.placeholder')"
        >
        </vca-input>
      </vca-field>
    </vca-card>
    <vca-card v-if="hasSystemPermission()" class="shadowed">
      <CrewSelect ref="crew" v-model="value" />
    </vca-card>
    <vca-card class="shadowed">
      <div>
        <h3>{{ $t("taking.form.date_of_taking.header") }}</h3>
        <vca-input-date
          ref="date_of_taking"
          v-model="date_of_taking"
          :default="Date.now() / 1000"
          :language="this.$i18n.locale"
          first
          :rules="$v.value.date_of_taking"
          :errorMsg="$t('taking.form.date_of_taking.errorMsg')"
          :placeholder="$t('taking.form.date_of_taking.placeholder_date')"
        />
      </div>
      <div>
        <h3>{{ $t("taking.form.sources.label") }}</h3>
        <vca-dropdown
          v-model="sources"
          ref="sources"
          :multiple="true"
          :rules="$v.value.sources"
          :errorMsg="$t('taking.form.sources.errorMsg')"
          :options="sourcesList"
          label=""
          :placeholder="$t('taking.form.sources.placeholder')"
        />
      </div>
      <TakingAddSource ref="source" v-model="value" />
    </vca-card>
  </vca-column>
</template>
<script>
import { mapGetters } from "vuex";
import CrewSelect from "@/components/finance/takings/form/CrewSelect";
import TakingAddSource from "@/components/finance/takings/add/TakingAddSource";
export default {
  name: "TakingAddForm",
  components: { CrewSelect, TakingAddSource },
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      valueLength: this.value.sources.length,
    };
  },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      this.$refs.name.validate();
      this.$refs.date_of_taking.validate();
      this.$refs.time_of_taking.validate();
      this.$refs.sources.validate();
      if (this.value.sources.length > 0) {
        this.$refs.source.validate();
      }
    },
  },
  computed: {
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      validation: "takings/validations",
    }),
    sourcesList: {
      get() {
        let s = this.$store.state.takings.sources.filter((el) => {
          el.title = this.$t(el.title);
          el.subtitle = this.$t(el.subtitle);
          el.label = this.$t(el.label);
          return true;
        });
        return JSON.parse(JSON.stringify(s));
      },
    },
    sources: {
      set(value) {
        this.value.sources = value;
      },
      get() {
        return this.value.sources;
      },
    },
    date_of_taking: {
      get() {
        return this.value.date_of_taking;
      },
      set(value) {
        let date = new Date(this.value.date_of_taking * 1000);
        let newDate = new Date(value * 1000);
        date.setFullYear(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate()
        );

        let newValue = this.value;
        newValue.date_of_taking = date.getTime() / 1000;
        this.value = newValue;
      },
    },
  },
};
</script>
